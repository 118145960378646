import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import config from '../../../config';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelLayout from '../../../components/PanelLayout';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import Swal from 'sweetalert2';
import prizesActions from '../../../context/benefits/actions';
import businessesActions from '../../../context/businesses/actions';
import companiesActions from '../../../context/companies/actions';
import transactionActions from '../../../context/transactions/actions';
import PrivateButton from '../../../components/commons/PrivateButton';
import { capitalize, getOwner } from '../../../libraries/utils';
import actions from '../../../context/transactions/actions';
import { format } from "date-fns";


const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class PrizesReserved extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      prizes: [],
      loading: false,
      windowWidth: window.innerWidth,
      transactions: [],
      sort: null,
      search: null
    };
    this.breadcrumbs = [capitalize(this.t('Premios Reservados'))];
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getTransactions();

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getCompanies = async () => {
    //await this.props.onGetCompanies({ owner: this.props.auth.user.id });
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) });

    if (this.props.companies.error) {
      notify(this.t(this.props.companies.error.messsage));
    } else {
      return this.props.companies.items
    }
  };

  getTransactions = async (page_num = 1) => {
    this.setState({ loading: false })
    const owner = await this.getCompanies();
    const params = {
      owner: owner[0].id,
      page_num,
      type: 'benefit',
      sub_type: 'prize',
      status: 'reserved',
      page_size: 8
    };
    
    await this.props.onGetAllTransactions(params);
    const { transactions } = this.props
    if (transactions.error) {
      notify(this.t(transactions.error.message));
    } else {
      
      this.setState({ transactions: transactions.items })
    }

  }

  confirmSingleOrMultiplePrizes = async (transactions) =>{
    const {value: swalResponse} = await customSwal.fire({
      title: transactions.length > 1 ? this.t("Desea confirmar los premios?") : this.t("Desea confirmar el premio?"),
      text: transactions.length > 1 ? this.t("Se cambiaran los premios seleccionados a confirmados") : this.t("Se cambiara el premio seleccionado a confirmado"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.t("Yes"),
      cancelButtonText: this.t("Cancel"),
    })

    if(swalResponse){
      let today = new Date()
      // const updatedTransactions = []
      // transactions.forEach(t => {
      //   updatedTransactions.push(
      //     this.props.onSaveTransaction({
      //       id: t.id,
      //       status: config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED,
      //       data: today.toISOString(),
      //       type: 'benefit'
      //     })

      //   )
      // });
      const updatedTransactions = transactions.map(t => 
        this.props.onSaveTransaction({
          id: t.id,
          status: config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED,
          date: today.toISOString(),
          type: 'benefit'
        })
      );
  
      await Promise.all(updatedTransactions);
       await this.getTransactions()

    }
  }

  render() {
    const { windowWidth, transactions, loading, query } = this.state;

    const columns = [
      {
        name: capitalize(this.t('name')),
        sortable: true,
        selector: row => row.json_data?.user?.first_name
      },
      {
        name: capitalize(this.t('last name')),
        sortable: true,
        selector: row => row.json_data?.user?.last_name
      },
      {
        name: capitalize(this.t('date')),
        sortable: true,
        selector: row => row.created_at.toISOString()
      },
      {
        name: capitalize(this.t('prize')),
        sortable: true,
        selector: row => row.json_data?.benefit?.name
      },
      {
        name: capitalize(this.t('status')),
        sortable: true,
        selector: row => row.status
      },
    ]

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        // actions={_actions}
        loading={loading}>
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={transactions}
            columns={columns}
            selectableRows
            query={query}
            getDataFunction={this.getTransactions}
            pagination
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
            extraTableFunctions={[
              {
                customFunction: this.confirmSingleOrMultiplePrizes,
                label: this.t("Confirmar premios"),
                icon: "gift",
              }
            ]}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    )
  }

}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    prizes: state.benefits.list,
    businesses: state.businesses.list,
    query: state.actions.list.query,
    transactions: state.transactions.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllPrizes: params => dispatch(prizesActions.getAll(params)),
    onSaveTransaction: (params) => dispatch(transactionActions.saveOrUpdate(params)),
    onGetBusinesses: params => dispatch(businessesActions.getAll(params)),
    onGetCompanies: params => dispatch(companiesActions.getAll(params)),
    onRemove: id => dispatch(prizesActions.del(id)),
    onGetTransactions: params => dispatch(actions.getAll(params)),
    onGetAllTransactions: params => dispatch(transactionActions.getAll(params)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PrizesReserved));
