import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import config from '../../../config';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import { capitalize, validateIsfilled, validateEmail, getOwner, removeDuplicates, validateOnlyNumber, removeAccents, removeAccentsWithOutN } from '../../../libraries/utils';
import FormLayout from '../../../components/forms/FormLayout';
import businessesActions from '../../../context/businesses/actions';
import usersActions from '../../../context/users/actions';
import rolesActions from '../../../context/roles/actions';

import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import TextInput from '../../../components/forms/TextInput';
import ExcelUploadFileInput from '../../../components/forms/ExcelUploadFileInput';
import Button from '../../../components/commons/Button';

import companiesActions from '../../../context/companies/actions';

// import usersTemplate from '../../../assets/usersTemplate.xlsx';
import users from '../../../assets/users.xlsx';

// const TableRows = ({rows}) => {
//   let header = rows.length ? rows[0] : []
//   let body = rows.length > 1 ? rows.slice(1,rows.length) : []
//   return (<>
//     <h3>Preview</h3>
//     <table className="table w-full">
//         <thead>
//             <tr>
//               {header.map(h => {return (<th>{h}</th>)})}
//             </tr>
//         </thead>
//         <tbody>
//           {body.map(h => {return (<tr>{h.map(e => {return (<th>{e}</th>)})}</tr>)})}
//         </tbody>
//     </table>
//   </>)
// }

const TableRowsSchema = ({ rows, errors }) => {
  let header = rows.length ? Object.keys(rows[0]) : []
  //{rows.map(e => {return (<th>{e}</th>)})}
  // console.log(rows, "rows")
  return (<>
    {errors && errors.map((e, index) => <p key={`error${index}`}>error: {e.error} row: {e.row} column: {e.column} value: {e.value}</p>)}
    {rows && <div className='overflow-x-auto'>
      <table className="table table-compact z-0">
        <thead>
          <tr>
            {header.map((h, index) => { return (<th key={`head${index}`}>{h}</th>) })}
          </tr>
        </thead>
        <tbody>
          {rows.map((r, index) => { return (<tr key={`tr${index}`}>{Object.keys(r).map((rr, index) => { return (<th key={`th${index}`}>{r[rr]}</th>) })}</tr>) })}
        </tbody>
      </table>
    </div>
    }
  </>)
}

class UploadExcelUsers extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      loading: true,
      creatingNew: false,
      files: {},
      submitting: false,
      imageUpdated: false,
      roles: [],
      location: '',
      view: false,
      editing: false,
      titles: '',
      userOptions: [],
      remove: false,
      rows: [],
      uploaded: 0,
      invalidColumns: [],
      validColumns: ['NOMBRE', 'APELLIDO', 'NÚMERO DE IDENTIFICACIÓN PERSONAL', 'MAIL', 'CUIL', 'SUCURSAL', 'AREA', 'TELÉFONO', 'PAÍS'],
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [capitalize(this.t('Usuarios')), this.t('Importar usuarios mediante archivo Excel (.xlsx)')];
    this.titles = this.t('Users');
    this.id=this.props.match.params.id
  }

  componentDidMount() {
    this.setState({
      titles: capitalize(this.t(`new business`)),
      creatingNew: true,
      loading: false,
      roles: this.props.auth.user.roles,
      //location
    });
    this.getData()
  }

  getData = async () => {
    await this.getCompany()
    await this.getAreas()
    await this.getSucursales()
    await this.getUsers()
  }

  getUsers = async () => {
    await this.props.onGetUsers({ unique_id: this.state.company_id });
    const { users } = this.props;
    if (users.error) {
      notify(this.t(users.error.message));
    } else {
      this.setState({ users: users.items, emails: users.items.map(u => u.email) });
    }
  }

  getCompany = async () => {
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) });
    const { companies } = this.props;
    if (companies.error) {
      notify(this.t(companies.error.message));
    } else {
      this.setState({ company_id: this.props.companies.items[0].id });
    }
  }

  getAreas = async () => {
    await this.props.onGetRoles({ type: 'group', owner: this.state.company_id });
    const { roles } = this.props;
    if (roles.error) {
      notify(this.t(roles.error.message));
    } else {
      this.setState({ areas: roles.items });
    }
  }

  getSucursales = async () => {
    await this.props.onGetBusinesses({ type: 'subsidiaries', owner: getOwner(this.props.auth.user) });
    const { businesses } = this.props;
    if (businesses.error) {
      notify(this.t(businesses.error.message));
    } else {
      this.setState({ sucursales: businesses.items });
    }
  }

  setBreadcrumbs = location => {
    this.breadcrumbs = [capitalize(this.t(location)), this.t('New')];
  };

  componentWillUnmount() {
  }

  goBack = () => {
    if (!this.state.location) history.push({
      pathname: this.props.location.state?.fromPath || config.ROUTES.HOME,


    });
  };

  uploadArea = async name => {
    await this.props.onSaveOrUpdateRoles({
      name: capitalize(name),
      description: `area de ${name} de ${this.props.auth.user.username}`,
      code: capitalize(name),
      type: "group",
      owner: this.state.company_id,
    });
    const role = this.props.role;

    if (role.error) {
      return null
    } else {
      return role.item
    }
  };

  uploadSucursal = async name => {
    await this.props.onSaveOrUpdateBusinesses({
      name: capitalize(name),
      type: 'subsidiaries',
      owner: getOwner(this.props.auth.user),
      json_data: {},
    });
    const business = this.props.business;
    if (business.error) {
      return null
    } else {
      return business.item
    }
  };

  sucursalStrInDbIncludes = (sucursal) => {
    let sucursales_in_db = this.state.sucursales.map(s => s.name)
    return sucursales_in_db.find(s => s.toLowerCase().includes(sucursal.toLowerCase())
    )
  }

  getRows = (rows, errors) => {
    console.log("___get rows", rows, errors)
    this.setState({ rows: [], excelErrors: null })
    if (!errors.length) {
      //console.log("rows", rows)
      if (this.state.sucursales) {
        let sucursales_in_db = this.state.sucursales.map(s => capitalize(s.name))
        //console.log("NEW SUCURSALES", sucursales_in_db)
        let sucursales = rows.map(r => r.subsidiary)
        //console.log("NEW SUCURSALES", sucursales_in_db)
        let new_sucursales = removeDuplicates(sucursales.filter(s => !sucursales_in_db.includes(capitalize(s)) && s !== undefined))
        this.setState({ new_sucursales: new_sucursales.length ? new_sucursales : null })
      }

      if (this.state.areas) {
        let areas_in_db = this.state.areas.map(s => s.name)
        let areas = rows.map(r => r.area)
        let new_areas = removeDuplicates(areas.filter(s => !areas_in_db.includes(s) && s !== undefined))
        this.setState({ new_areas: new_areas.length ? new_areas : null })
      }

      this.setState({ rows, previewReady: true })
    } else {
      console.log(errors)
      this.setState({ excelErrors: errors, previewReady: false })
    }
  }

  uploadRows = () => {

  }

  saveOne = async (data) => {
    data = {
      ...data,
      welcome_email: false,
      verify_email: false,
      invitation_email: false,
    }
    if(this.id) data.invited_by=this.id

    
    await this.props.onSaveOrUpdate({ ...data, role: "", });
    const user = this.props.user;
    if (user.error) {
      notify(this.t(user.error.message));
      return null
    } else {
      //this.vehicles current id save
      //this.setState({uploaded: this.state.uploaded+1})
      return user.item
    }
  }

  saveOneCompanyUser = async (data) => {
    await this.props.onSaveOrUpdate({ ...data, role: "", });
    const user = this.props.user;
    if (user.error) {
      notify(this.t(user.error.message));
      return null
    } else {
      return user.item.id
    }
  }

  saveOneCompany = async (data) => {
    await this.props.onSaveOrUpdateCompany({ ...data, role: "", });
    const company = this.props.company;
    if (company.error) {
      notify(this.t(company.error.message));
      return null
    } else {
      //this.vehicles current id save
      //this.setState({uploaded: this.state.uploaded+1})
      return company.item.id
    }
  }


  onSubmit = async values => {

  };

  getUserObject = (values, areas, sucursales) => {
    let obj = {
      email: values.email.toLowerCase(),
      first_name: capitalize(values.first_name.toLowerCase()),
      last_name: capitalize(values.last_name.toLowerCase()),
      identification: values.identification.replaceAll(".", ""),
      identification_type: "DNI",
      user_type: "regular",
      password: "12345",
      unique_id: this.state.company_id,
      //meta_title: values.subsidiary ? sucursales.find(s => s.name.toLowerCase() === values.subsidiary.toLowerCase())?.id : null,
      //meta_description: values.area ? areas.find(a => a.name.toLowerCase() === values.area.toLowerCase())?.id : null,
      labor_identification_code: values.labor_identification_code?.replaceAll(".", "")?.replaceAll("-", ""),
      phone: values.phone,
      // location: values.location?.toUpperCase(),
      subsidiary_id: values.subsidiary ? sucursales.find(s => s.name.toLowerCase() === values.subsidiary.toLowerCase())?.id : null,
      group_id: values.area ? areas.find(a => a.name.toLowerCase() === values.area.toLowerCase())?.id : null,
      location: values.pais.toUpperCase(),
      // phone:values.telefono,
      json_data: {},
    }

    return obj
  }

  getCompanyUserObject = (values, owner) => {
    let obj = {
      email: values.email.toLowerCase(),
      first_name: capitalize(values.first_name),
      last_name: capitalize(values.last_name),
      owner,
      user_type: "company",
      json_data: {},
    }

    return obj
  }

  getCompanyObject = (name, owner) => {
    return {
      type: "company",
      name,
      owner,
      json_data: {},
    }
  }

  uploadAll = async values => {
    console.log(this.state.rows)
    this.setState({ uploading: true, uploadQuantity: this.state.rows.length })

    let sucursales = [...this.state.sucursales]
    let areas = [...this.state.areas]

    if (this.state.new_sucursales) {
      console.log("NEW SUCURSALES", this.state.new_sucursales)
      let sucursales_promises = [];
      for (let i = 0; i < this.state.new_sucursales.length; i++) {
        sucursales_promises.push(this.uploadSucursal(this.state.new_sucursales[i]))
        let new_sucursales_ = await Promise.all(sucursales_promises)
        sucursales = [...sucursales, ...new_sucursales_]
      }
    }

    if (this.state.new_areas) {
      let areas_promises = [];
      for (let i = 0; i < this.state.new_areas.length; i++) {
        areas_promises.push(this.uploadArea(this.state.new_areas[i]))
        let new_areas_ = await Promise.all(areas_promises)
        areas = [...areas, ...new_areas_]
      }
    }
    // Obtener y crear primero los referentes

    const listReferentes= this.state.rows.filter(r=>r.referente==1)
    console.log(listReferentes)
    let promises = [];
    for (let i = 0; i <listReferentes.length; i++) {
      const dataReferentes=this.getUserObject(listReferentes[i], areas, sucursales)
      dataReferentes.invited_by = "referent"
      console.log(dataReferentes)
      promises.push(this.saveOne(dataReferentes))
    }

    let referentes = []
    let upload_range = 30 //mas de 40 al mismo tiempo tira error

    for (let i = 0; i < promises.length; i += upload_range) {
      let newRef = await Promise.all(promises.slice(i, i + upload_range))
      referentes = [ ...newRef,...referentes]
    }
    console.log("ids", referentes)

    // Dar de dalta los colaboradores
    let promisesColaboradores = [];
    let idReferente = null;
    for (let i = 0; i <this.state.rows.length; i++) {
      let infoRow=this.state.rows[i]
      console.log(infoRow);
      
      if(infoRow.referente==1){
        console.log(referentes);
        
        let referenteEncontrado =  referentes.find(r=>r.email.toLowerCase()==infoRow.email.toLowerCase()) 
        console.log(referenteEncontrado);
        idReferente =  referenteEncontrado.id
        console.log("idReferente", idReferente)
        continue;
      }
      const dataColaborador=this.getUserObject(this.state.rows[i], areas, sucursales)
      console.log("idReferente", idReferente)
      if(idReferente)dataColaborador.invited_by = idReferente
      
      console.log(dataColaborador)
      promisesColaboradores.push(this.saveOne(dataColaborador))
    }

    let colaboradores = []
    
    for (let i = 0; i < promises.length; i += upload_range) {
      let newRef = await Promise.all(promises.slice(i, i + upload_range))
      colaboradores = [ ...colaboradores,...newRef]
    }
    console.log("colaboradores", colaboradores)

    this.setState({ uploading: false, uploaded: 0, upload_finished: true, users_uploaded: referentes.length })

    //console.log(ids)
  }

  getSchemaFromValues = values => {
    // console.log("_________values", values)
    let sucursales = null
    if (values.sucursales) {
      if (values.sucursales.includes(",")) {
        sucursales = values.sucursales.split(",").map(s => s.toLowerCase())
      }
    }

    let areas = null
    if (values.areas) {
      if (values.areas.includes(",")) {
        areas = values.areas.split(",").map(s => s.toLowerCase())
      }

    }

    const schema = {
      //Sucursal  Area
      'NOMBRE': {
        prop: 'first_name',
        type: String,
        required: true,
      },
      'APELLIDO': {
        prop: 'last_name',
        type: String,
        required: true,
      },
      'NÚMERO DE IDENTIFICACIÓN PERSONAL': {
        prop: 'identification',
        //type: String //validate only numbers
        type: (value) => {
          const value_ = value.toString().replaceAll(".", "").replaceAll(" ", "").replaceAll(",", "")
          //validateOnlyNumber(value_) &&
          const dni = value_.length >= 5 && value_.length <= 19
          if (!dni) {
            throw new Error(`Documento de Identidad ${value} inválido`)
          }
          return value.toString()
        },
        required: true,
      },
      'MAIL': {
        prop: 'email',
        //type: String
        type: (value) => {
          const mail = validateEmail(value)
          if (!mail) {
            throw new Error(`El mail ${value} inválido`)
          }
          if (this.state.emails.includes(value)) {
            //fixme: chequear tambien que no se repita el mismo mail en el mismo archivo
            throw new Error(`El mail ${value} ya se encuentra en uso`)
          }
          return value
        },
        required: true,
      },
      "TELÉFONO": {
        prop: 'phone',
        type: Number,
        // required:true,
      },
      "PAÍS": {
        prop: "pais",
        // type: String,
        required: true,
        type: (value) => {
          const paisSinAcento = removeAccents(value)

          if (!config.OPTIONS.COUNTRIES.includes(paisSinAcento?.toLowerCase()) && !config.OPTIONS.COUNTRIES_ES.includes(paisSinAcento?.toLowerCase())) {
            //fixme: chequear tambien que no se repita el mismo mail en el mismo archivo
            throw new Error(`El pais ${value} es invalido`)
          }
          return removeAccentsWithOutN(value)
        },
      },
      'CUIL': {
        prop: 'labor_identification_code',
        type: String,
        // required:true,

      },
      'SUCURSAL': {
        prop: 'subsidiary',
        type: String,
        oneOf: sucursales,
      },
      'AREA': {
        prop: 'area',
        type: String,
        oneOf: areas,
      },
      'REFERENTE': {
        prop: 'referente',
        type: Number, 
        required: true,
      },
    }
    return schema
  }

  checkRows = (rows) => {
    if (rows.length) {
      rows[0].forEach(r => {
        if (!this.state.validColumns.includes(r)) {
          this.setState({ invalidColumns: [...this.state.invalidColumns, r] })
        }
      })
    }
  }

  render() {
    const { view, editing, creatingNew, submitting, loading, roles } = this.state;
    // las opciones: generar un array de propietarios de tipo benefit y de tipo initiatives
    // y asi en base a eso, hacer el when field changes do y cambiar el array de propietarios
    // ** Options for selects
    // let userOptions = [];

    // const countries = selectGeneratorWObjChild(config.OPTIONS.COUNTRIES, '', o => capitalizePhrase(o));
    // const argentinaStates = selectGeneratorWObjChild(config.OPTIONS.STATES.ARGENTINA, '', o => capitalizePhrase(o));

    // Layout actions
    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing),
        visible: creatingNew || roles.includes(config.ROLES.BENEFITS) || roles.includes(config.ROLES.INITIATIVES),
        checkPermissions: 'update'
      },
      secondaries: [
        {
          onClick: e => this.goBack(),
          title: this.t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting || loading,
          visible: true,
          className: 'btn-accent'
        },
        {
          onClick: e => {
            const link = document.createElement('a')
            link.href = users
            link.download = 'users.xlsx'
            document.body.appendChild(link)
            link.click();
            document.body.removeChild(link)
          },
          title: this.t('Download Template'),
          visible: true,
          className: 'btn-accent'
        },
        {
          onClick: e => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting,
          visible:
            (roles.includes(config.ROLES.BENEFITS) || roles.includes(config.ROLES.INITIATIVES)) && view && !editing,
          checkPermissions: 'update'
        },
        {
          onClick: e => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing,
          visible: (roles.includes(config.ROLES.BENEFITS) || roles.includes(config.ROLES.INITIATIVES)) && editing
        },
        {
          onClick: e => { },
          title: this.t(false ? 'Disable' : 'Enable'),
          icon: false ? 'view_show' : 'view_hide',
          disabled:
            (roles.includes(config.ROLES.ADMIN) && (submitting || (view && !editing) || loading)) ||
            (roles.includes(config.ROLES.COMPANIES) && (submitting || loading)),
          checkPermissions: 'insert',
          visible: !creatingNew
        }
      ]
    };

    // ** Form validation functions
    const required = value =>
      validateIsfilled(value) || (view && !editing) ? undefined : this.t('This field is required');
    const validateForm = values => {
      const errors = {};
      errors.name = required(values.name);
      errors.country = required(values.country);
      return errors;
    };

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-0 md:px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outterTitle={this.titles} loading={loading}>
          {!this.state.upload_finished && <>
            <div className='text-sm mx-3'>
              <li>Puede descargar el template desde el boton superior y empezar a completar en él</li>
              {/* <li>Si la empresa no tiene sucursales o areas, borrar las columnas del excel</li>  */}
              <li className='mb-5'>El archivo debe tener la extensión .xlsx y tener las siguientes columnas: </li>
            </div>
            <TableRowsSchema rows={[{ "NOMBRE": "", "APELLIDO": "", "NÚMERO DE IDENTIFICACIÓN PERSONAL": "", "MAIL": "", "TELÉFONO": "", "PAÍS": "", "CUIL(OPCIONAL)": "", "SUCURSAL(OPCIONAL)": "", "AREA(OPCIONAL)": "", "REFERENTE":"" }]} />
            <div className='text-sm mx-3 my-5'>
              <li className=''>Las columnas que dicen OPCIONAL quiere decir que pueden estar o no, la columna no debe incluir el 'opcional', si no no se van a cargar correctamente </li>
              <li className=''>Los valores cargados en las columnas sucursal o area generaran automáticamente una nueva sucursal o area</li>
            </div>
          </>}

          <Form initialValues={{}} onSubmit={this.onSubmit} validate={validateForm}>
            {({ handleSubmit, form, submitting, pristine, values }) => {
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                  {false && <>
                    <Field name="sucursales" component={TextInput} placeholder={this.t('sucursales')} label={this.t('opcional: ingresar nombres de sucursales separados por comas para no crear duplicados')} />
                    <Field name="areas" component={TextInput} placeholder={this.t('areas')} label={this.t('opcional: ingresar nombres de areas separados por comas para no crear duplicados')} />
                  </>}
                  <div className="flex justify-center mb-3 md:mb-6">
                    <Field name="test_excel" component={ExcelUploadFileInput} getRows={this.getRows} schema={this.getSchemaFromValues(values)} checkRows={this.checkRows} className="w-11/12 md:w-full" />
                  </div>
                </FormLayout>
              );
            }}
          </Form>
          {!!this.state.invalidColumns.length &&
            <>
              <p>Las siguientes columnas del archivo excel son inválidas: {this.state.invalidColumns.join(", ")}</p>
              <p>Chequear que las columnas del archivo excel sean: {this.state.validColumns.join(", ")}</p>
            </>}
          {!!this.state.rows?.length && <div className='flex flex-col gap-3 my-5'>
            {this.state.new_sucursales &&
              this.state.new_sucursales.length ?
              <p>{`ADVERTENCIA: Las siguientes sucursales serán creadas automáticamente: ${this.state.new_sucursales.join(", ")}`}</p> :
              <p>{`ADVERTENCIA: NO SE CREARÁ NINGUNA SUCURSAL NUEVA, PARA AUTOGENERAR NUEVAS SUCURSALES AGREGARLAS A LA COLUMNA SUCURSAL DEL ARCHIVO EXCEL`}</p>}
            {this.state.new_areas &&
              this.state.new_areas.length ?
              <p>{`ADVERTENCIA: Las siguientes areas serán creadas automáticamente: ${this.state.new_areas.join(", ")}`}</p> :
              <p>{`ADVERTENCIA: NO SE CREARÁ NINGÚN AREA NUEVA, PARA AUTOGENERAR NUEVAS AREAS AGREGARLAS A LA COLUMNA AREA DEL ARCHIVO EXCEL`}</p>
            }
          </div>}

          <h3 className='mb-3'>Preview</h3>
          <TableRowsSchema rows={this.state.rows} errors={this.state.excelErrors} />
          {this.state.uploading && <><p>cargando...</p>
            <p>{this.state.uploaded} de {this.state.uploadQuantity}</p>

            <progress className="progress w-56" value={this.state.uploaded} max={this.state.uploadQuantity}></progress></>}

          {!this.state.upload_finished && <Button
            className="btn-primary btn-block mt-5"
            title={this.t("Cargar")}
            onClick={this.uploadAll}
            disabled={!this.state.previewReady}
          />}

          {this.state.upload_finished && <p>Carga finalizada, se cargaron {this.state.users_uploaded} usuarios con éxito.</p>}

        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}



const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    user: state.users.current,
    users: state.users.list,
    company: state.companies.current,
    companies: state.companies.list,
    role: state.roles.current,
    roles: state.roles.list,
    business: state.businesses.current,
    businesses: state.businesses.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveOrUpdate: params => dispatch(usersActions.saveOrUpdate(params)),
    onSaveOrUpdateCompany: params => dispatch(companiesActions.saveOrUpdate(params)),
    onGetUsers: params => dispatch(usersActions.getAll(params)),
    onGetCompanies: params => dispatch(companiesActions.getAll(params)),
    onSaveOrUpdateRoles: params => dispatch(rolesActions.saveOrUpdate(params)),
    onGetRoles: params => dispatch(rolesActions.getAll(params)),
    onSaveOrUpdateBusinesses: params => dispatch(businessesActions.saveOrUpdate(params)),
    onGetBusinesses: params => dispatch(businessesActions.getAll(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UploadExcelUsers));
