import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import config from '../../config';
import { history } from '../../routes';
import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import PanelLayout from '../../components/PanelLayout';
import StyledDataTable from '../../components/commons/StyledDataTable';
import transactionActions from '../../context/transactions/actions';
import challengesActions from '../../context/challenges/actions';
import categoriesActions from '../../context/categories/actions';
import companyActions from '../../context/companies/actions';
import usersActions from '../../context/users/actions';
import { isEmptyObject, capitalize, getOwner } from '../../libraries/utils';
import ButtonIcon from '../../components/commons/ButtonIcon';
import { exportToExcel } from '../../libraries/exports/exportToExcel';
import clsx from "clsx";
import Swal from 'sweetalert2';


const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class BenefitsConsumptions extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      // default type
      // currentType: 'all',
      sort: {
        order_by: 'sub_type',
        order_direction: 'DESC'
      },
      loading: true,
      transactions: [],
      users: [],
      currentType: "maslow",
      windowWidth: window.innerWidth
    };
    this.breadcrumbs = [capitalize(this.t('Beneficios consumidos con créditos'))];
  }

  componentDidMount() {
    this.load();
  }
  
  async load() {
    // used to display user data in some columns
    await this.getCompanyId()
    // await this.getUsers();
    await this.getTransactions();
    // used for the select
    this.getActionTypesAndUpdateTable();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getActionTypesAndUpdateTable = async () => {
    await this.props.onGetActionTypes({ type: config.TYPES.CATEGORIES.BENEFITS });
    const { categories } = this.props;
    if (categories.error) {
      notify(this.t(categories.error.message));
    } else {
      const trx = this.state.transactions
      const trxWithCategory = trx.map( t => ({
        ...t,
        json_data: {
          ...t.json_data,
        },
        category: categories.items.find( c => c.id === t.json_data.benefit.pack)
      }))
      console.log("trxWithCategory", trxWithCategory)

      this.setState({ transactions: trxWithCategory });
    }
  };

  getUsers = async () => {
    await this.props.onGetAllUsers();
    const { users } = this.props;
    if (users.error) {
      notify(this.t(users.error.message));
    } else {
      this.setState({ users: users.items });
    }
  };

  getCompanyId = async () => {
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) });
    const { companies } = this.props;
    if (companies.error) {
      notify(this.t(companies.error.message));
    } else {
      if (this.props.companies.items.length) {
        this.setState({ company_id: this.props.companies.items[0].id });
        console.warn("company", this.props.companies.items[0].id);
      }
    }
  };

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getTransactions = async (page_num = 1, type = this.state.currentType) => {
    const { company_id } = this.state;
    this.setState({loading: true})
    const params = {
      page_num,
      type,
      page_size: 8,
      owner: company_id,
      source: company_id
    };
    if(page_num === "export") {
      delete params.page_num 
      delete params.page_size 
    }
    if(type === "maslow"){
      params.type = "benefit"
      params.sub_type = ["maslow"]        
    }

    const { search } = this.state;

    if (search && search !== '') {
      params.where = {
        custom: {
          type: `%${search.toLowerCase()}`,
          comparison: 'OR'
        }
      };
    }

    if (!isEmptyObject(this.state.sort)) {
      params.order_by = this.state.sort.order_by;
      params.order_direction = this.state.sort.order_direction;
    }

    await this.props.onGetAllTransactions(params);
    const { transactions } = this.props;
    if (transactions.error) {
      notify(this.t(transactions.error.message));
    } else {
      const translatedTransactions = transactions.items.map( t => {
        let newTrx = { ...t };
        if (t.type === "request") {
          newTrx.json_data.category.type = this.t(t.json_data.category.type);
        } else {
          newTrx.sub_type = this.t(t.sub_type);
        }
        return newTrx;
      })
      if(page_num === "export") {
        this.setState({ loading: transactions.loading });
        return translatedTransactions
      }
      this.setState({ transactions: translatedTransactions, loading: transactions.loading });
      await this.getActionTypesAndUpdateTable()
    }
  };

  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () => this.getTransactions(currentPage));
  };
  
  onSearch = data => {
    if (this.state.search !== data.search) this.setState({ search: data.search || '' }, () => this.getTransactions());
  };

  searchByType = async type => {
    this.setState({ currentType: type }, () => this.getTransactions());
  };

  hide = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  searchClear = form => {
    form.change('search', undefined);
    this.onSearch({ search: '' });
  };
  //  ** Delete function
  onRemove = (id, resetRows) => {
    const { transactions } = this.props;
    customSwal
      .fire({
        title: this.t(resetRows ? capitalize('delete multiple elements') : '¿Are you sure?'),
        text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          // ** Loading
          transactions.loading = true;
          // ** If the parameter recieved is an array, execute multiple delete function
          if (resetRows) {
            for (let index = 0; index < id.length; index++) {
              const element = id[index];
              // ** Wait until the api removes selected items
              await this.props.onRemove(element.id);
            }
            resetRows();
            if (transactions.error) {
              notify(this.t(transactions.error.message));
            } else {
              // ** Get actions
              this.getTransactions(this.state.currentPage);
            }
            // ** If the parameter is a number execute unique delete
          } else {
            // ** wait until the api removes selected items
            await this.props.onRemove(id);
            if (transactions.error) {
              notify(this.t(transactions.error.message));
            } else {
              // ** Get actions
              this.getTransactions(this.state.currentPage);
            }
          }
        }
      });
  };
  // ** Redirection to edit action route
  onEdit = id => {
    history.push(config.ROUTES.BENEFIT_CONSUMPTION.replace(":id",id));
  };

  onConsume = async id => {
    const { transaction } = this.props;
    customSwal
      .fire({
        title: this.t('¿Consume benefit?'),
        // text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          // ** Loading
          transaction.loading = true;
          const data = {
            id,
            status: config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED
          }
          // ** Update transaction
          await this.props.onSaveOrUpdateTransaction(data)
          if (transaction.error) {
            notify(this.t(transaction.error.message));
          } else {
            // ** Get transactions
            this.getTransactions(this.state.currentPage);
          }
        }
      });
  }


  render() {
    const { windowWidth, transactions, actionCategoryTypes, loading } = this.state;
    /* Configure columns shown on table
    New ones can be added by creating new objects */
    const columns = [
      {
        name: this.t('Nombre'), // EL BENEFICIO
        maxWidth: '32vw',
        sortable: true,
        selector: row => row.json_data.benefit.name,
        sortSelector: 'name'
      },
      {
        name: capitalize(this.t('category')),
        maxWidth: '16vw',
        sortable: true,
        selector: row => row.category?.name || row.json_data.benefit.pack,
        
        sortSelector: 'pack'
      },
      {
        name: this.t('Colaborador'),
        maxWidth: '16vw',
        sortable: true,
        selector:  row => `${row.json_data.user.first_name} ${row.json_data.user.last_name}`,
        sortSelector: 'source',
        // hide: 'md'
      },
      {
        name: capitalize(this.t('creditos')),
        maxWidth: '8vw',
        sortable: true,
        selector: row => row.json_data.credits,
        // hide: this.hide(1280)
      },
      {
        name: capitalize(this.t('reclamado')),
        maxWidth: '8vw',
        sortable: true,
        selector: row => row.created_at.toLocaleDateString(),
        sortSelector: 'transactions__created_at',
        // hide: this.hide(1280)
      },
      {
        name: capitalize(this.t('consumido')),
        maxWidth: '8vw',
        sortable: true,
        selector: row => row.updated_at ? new Date(row.updated_at).toLocaleDateString() : '',
        sortSelector: 'transactions__updated_at',
        // hide: this.hide(1280)
      },
      {
        name: this.t('Status'),
        // hide: this.hide(1100),
        maxWidth:
          // 720p
          windowWidth < 1920
            ? '10.48vw'
            : // 1080p
            windowWidth >= 1920 && windowWidth < 3840
            ? '11.89vw'
            : // 4k
            windowWidth >= 3840
            ? '13.56vw'
            : '',
        selector: row => <p className={
          // clsx("rounded-box px-2 py-1 capitalize", 
          //   row.status === "canceled" ? "bg-red-100 ": row.status === 'generated' ? 'bg-yellow-100' : "bg-green-100 ")}
          clsx("rounded-box px-2 py-1 capitalize bg-green-100",{
            "bg-red-100": row.status === "canceled",
            "bg-yellow-100": row.status === 'generated',
          })}
        >
          {row.status === "canceled" ? "Cancelado" : row.status === 'generated' ? 'Nuevo' : "Consumido"}
        </p>
      },
      {
        name: 'Acciones',
        allowOverflow: true,
        center: true,
        width: '130px',
        hide: this.hide(370),
        cell: row => {
          return (
            <div className="flex justify-center align-center rounded-full gap-2">
                {!this.props.auth.user?.accounts && row.status !== 'consumed' ?
                  <ButtonIcon onClick={() => this.onConsume(row.id)} icon="checkmark" buttonClassName="btn-link text-error bg-gray-100 btn-sm" /> : null
                }
              <ButtonIcon onClick={() => this.onEdit(row.id)} icon="view_show" buttonClassName="btn-link text-gray-600 bg-gray-100 btn-sm" className="w-5" />
            </div>
          );
        }
      },
    ];



    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        loading={loading}>
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={transactions}
            columns={columns}
            query={this.props.transactions.query}
            getDataFunction={this.getTransactions}
            pagination
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
            /* boolean check, request doesn't need an expandable component
            as there isn't enough data */
            /*             expandableRows={currentType !== 'request' ? true : false}
            expandableRowsComponent={expandableComponent} */
            onRowExpand={() => {}}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    transactions: state.transactions.list,
    transaction: state.transactions.current,
    categories: state.categories.list,
    users: state.users.list,
    challengeBenefit: state.challenges.challengeBenefit,
    challenges: state.challenges.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllTransactions: params => dispatch(transactionActions.getAll(params)),
    onSaveOrUpdateTransaction: data => dispatch(transactionActions.saveOrUpdate(data)),
    onRemove: id => dispatch(transactionActions.del(id)),
    onGetAllCompanies: params => dispatch(companyActions.getAll(params)),
    onGetActionTypes: id => dispatch(categoriesActions.getAll(id)),
    onGetAllUsers: params => dispatch(usersActions.getAll(params)),
    onGetChallengeBenefit: id => dispatch(challengesActions.getChallengeBenefit(id)),
    onGetChallenges: params => dispatch(challengesActions.getAll(params)),
    onGetCompanies: (params) => dispatch(companyActions.getAll(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BenefitsConsumptions));