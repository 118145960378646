const dotenv = {
  DOMAIN_NAME: process.env.REACT_APP_DOMAIN_NAME || 'http://panel.sharyco.com',
  // BASE_API_URL: process.env.REACT_APP_BASE_API_URL || 'https://apidemo.sharyco.com/api/v1',
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL || 'https://api.sharyco.com/api/v1',
  DEBUG_MODE: process.env.REACT_APP_DEBUG_MODE || false,
  // ONESIGNAL_APPID: process.env.REACT_APP_ONESIGNAL_APPID || 'xx',
  // ONESIGNAL_SAFARI_WEB_ID: process.env.REACT_APP_ONESIGNAL_SAFARI_WEB_ID || 'web.onesignal.auto.xxx',
}

const vars = {
  VERSION: 'v20210129.001',
  CLIENT_ID: '11111111111',
  CLIENT_SECRET: 'qwetqutuqwteutuqwteuqwtuetqwyteuqt',
};


const general = { ...vars, ...dotenv };
export default general;