import { createBrowserHistory } from 'history';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useLocation } from 'react-router-dom';
import { PrivateRoute } from '../components/commons/PrivateRoute';
import config from '../config';

// Action (seguí sumando) components -----------
import Action from '../pages/crud/actions/Action';
import Actions from '../pages/crud/actions/Actions';
// Business components -------------------------
import Business from '../pages/crud/businesses/Business';
import Businesses from '../pages/crud/businesses/Businesses';
import MyBusiness from '../pages/crud/businesses/MyBusiness';
import MyOrganization from '../pages/crud/businesses/MyOrganization';

// Prize components -------------------------
import Prize from '../pages/crud/prizes/Prize';
import Prizes from '../pages/crud/prizes/Prizes';
import PrizesReserved from '../pages/crud/prizesReserved/PrizesReserved';
// Category components -------------------------
import Categories from '../pages/crud/categories/Categories';
import Category from '../pages/crud/categories/Category';
// UI components -------------------------------
import Dashboard from '../pages/Dashboard';
import Error from '../pages/commons/Error';
// Initiative components -----------------------
import Initiative from '../pages/crud/initiatives/Initiative';
import Initiatives from '../pages/crud/initiatives/Initiatives';
// Info components -----------------------------
import InfoContactUs from '../pages/info/InfoContactUs';
import InfoHelp from '../pages/info/InfoHelp';
import InfoPrivacy from '../pages/info/InfoPrivacy';
import InfoTerms from '../pages/info/InfoTerms';
// Message components --------------------------
import Message from '../pages/crud/messages_reclamos/Message';
import Messages from '../pages/crud/messages_reclamos/Messages';
// Product components --------------------------
import Product from '../pages/crud/products/Product';
import Products from '../pages/crud/products/Products';
// Transaction components ----------------------
import Assistances from '../pages/crud/transactions/Assistances';
import Consumptions from '../pages/crud/transactions/Consumptions';
import Transactions from '../pages/crud/transactions/Transactions';
// Benefit components --------------------------
import Benefit from '../pages/crud/benefits/Benefit';
import Benefits from '../pages/crud/benefits/Benefits';
import BenefitConsumption from '../pages/benefits/BenefitConsumption';
import BenefitsConsumptions from '../pages/benefits/BenefitsConsumptions';
// Role components --------------------------
import Role from '../pages/crud/roles/Role';
import Roles from '../pages/crud/roles/Roles';
// Settings ------------------------------------
import Settings from '../pages/crud/settings/MySettings';
// User components -----------------------------
// COMPANY
import UserForgot from '../pages/auth/UserForgot';
import UserInfo from '../pages/auth/UserInfo';
import UserLogin from '../pages/auth/UserLogin';
import UserPassword from '../pages/auth/UserPassword';
import UserPasswordChange from '../pages/auth/UserPasswordChange';
import UserProfile from '../pages/auth/UserProfile';
import UserSignup from '../pages/auth/UserSignup';
import UserVerify from '../pages/auth/UserVerify';
import Companies from '../pages/crud/companies/Companies';
import Company from '../pages/crud/companies/Company';
import MyCompany from '../pages/crud/companies/MyCompany';
import User from '../pages/crud/users/Colaborador';
import Users from '../pages/crud/users/Colaboradores';

import Colaborador from '../pages/crud/administradores/Administrador';
import Colaboradores from '../pages/crud/administradores/Administradores';

import ScanQR from '../pages/initiatives/ScanQR';

import BenefitConsume from '../pages/benefits/BenefitConsume';
import BenefitConsumeConfirmation from '../pages/benefits/BenefitConsumeConfirmation';

import ChallengesEdit from '../pages/challenges/ChallengesEdit';
import ChallengesList from '../pages/challenges/ChallengesList';
import UploadExcelUsers from '../pages/crud/excel/UploadExcelUsers';

import Referent from '../pages/crud/referents/Referent';
import Referents from '../pages/crud/referents/Referents';

import UserRanking from '../pages/ranking/UserRanking';
import Credits from '../pages/credits/Credits';
import OneUserHistory from '../pages/credits/OneUserHistory';
import ActionsOrder from '../pages/crud/actions/ActionsOrder';
import Moderador from '../pages/crud/moderador/Moderador';

export const history = createBrowserHistory();

export const Routes = () => {
  const location = useLocation();
  const { t } = useTranslation();  

  return (
    <Switch location={location}>
      <Route path={config.ROUTES.TERMS} exact component={InfoTerms} />
      <Route path={config.ROUTES.CONTACT_US} exact component={InfoContactUs} />
      <Route path={config.ROUTES.PRIVACY} exact component={InfoPrivacy} />
      <Route path={config.ROUTES.HELP} exact component={InfoHelp} />

      <Route path={config.ROUTES.LOGIN} exact component={UserLogin} />
      <Route path={config.ROUTES.SIGNUP} exact component={UserSignup} />
      <Route path={config.ROUTES.SIGNUP_FINALIZE} exact component={UserSignup} />
      <Route path={config.ROUTES.PASSWORD} exact component={UserPassword} />
      <Route path={config.ROUTES.VERIFY} exact component={UserVerify} />
      <Route path={config.ROUTES.FORGOT} exact component={UserForgot} />

      {/* Private routes */}
      <PrivateRoute path={config.ROUTES.ACTIONS} exact component={Actions} />
      <PrivateRoute path={config.ROUTES.ACTIONS_NEW} exact component={Action} />
      <PrivateRoute path={config.ROUTES.ACTIONS_ORDER} exact component={ActionsOrder} />
      <PrivateRoute path={config.ROUTES.ACTIONS_EDIT} exact component={Action} />

      <PrivateRoute path={config.ROUTES.BUSINESSES} exact component={Businesses} />
      <PrivateRoute path={config.ROUTES.BUSINESSES_NEW} exact component={Business} />
      <PrivateRoute path={config.ROUTES.BUSINESSES_EDIT} exact component={Business} />

      <PrivateRoute path={config.ROUTES.ENTITIES} exact component={Businesses} />
      <PrivateRoute path={config.ROUTES.ENTITIES_NEW} exact component={Business} />
      <PrivateRoute path={config.ROUTES.ENTITIES_EDIT} exact component={Business} />

      <PrivateRoute path={config.ROUTES.SUBSIDIARIES} exact component={Businesses} />
      <PrivateRoute path={config.ROUTES.SUBSIDIARIES_NEW} exact component={Business} />
      <PrivateRoute path={config.ROUTES.SUBSIDIARIES_EDIT} exact component={Business} />

      <PrivateRoute path={config.ROUTES.PRIZES} exact component={Prizes} />
      <PrivateRoute path={config.ROUTES.PRIZES_NEW} exact component={Prize} />
      <PrivateRoute path={config.ROUTES.PRIZES_EDIT} exact component={Prize} />
      <PrivateRoute path={config.ROUTES.PRIZE_RESERVED} exact component={PrizesReserved} />

      <PrivateRoute path={config.ROUTES.COMPANIES} exact component={Companies} />
      <PrivateRoute path={config.ROUTES.COMPANIES_NEW} exact component={Company} />
      <PrivateRoute path={config.ROUTES.COMPANIES_EDIT} exact component={Company} />

      <PrivateRoute path={config.ROUTES.MY_COMPANY} exact component={MyCompany} />
      <PrivateRoute path={config.ROUTES.MY_BUSINESS} exact component={MyBusiness} />
      <PrivateRoute path={config.ROUTES.MY_ORGANIZATION} exact component={MyOrganization} />


      <PrivateRoute path={config.ROUTES.CATEGORIES} exact component={Categories} />
      <PrivateRoute path={config.ROUTES.CATEGORIES_NEW} exact component={Category} />
      <PrivateRoute path={config.ROUTES.CATEGORIES_EDIT} exact component={Category} />

      {/* <PrivateRoute path={config.ROUTES.INITIATIVES} exact component={Initiatives} />
      <PrivateRoute path={config.ROUTES.INITIATIVES_NEW} exact component={Initiative} />
      <PrivateRoute path={config.ROUTES.INITIATIVES_EDIT} exact component={Initiative} /> */}

      <PrivateRoute path={config.ROUTES.MESSAGES} exact component={Messages} />
      <PrivateRoute path={config.ROUTES.MESSAGES_NEW} exact component={Message} />
      <PrivateRoute path={config.ROUTES.MESSAGES_EDIT} exact component={Message} />

      <PrivateRoute path={config.ROUTES.PRODUCTS} exact component={Products} />
      <PrivateRoute path={config.ROUTES.PRODUCTS_NEW} exact component={Product} />
      <PrivateRoute path={config.ROUTES.PRODUCTS_EDIT} exact component={Product} />

      <PrivateRoute path={config.ROUTES.DEPARTMENTS} exact component={Roles} />
      <PrivateRoute path={config.ROUTES.DEPARTMENTS_NEW} exact component={Role} />
      <PrivateRoute path={config.ROUTES.DEPARTMENTS_EDIT} exact component={Role} />

      <PrivateRoute path={config.ROUTES.USER.PASSWORD_CHANGE} exact component={UserPasswordChange} />
      <PrivateRoute path={config.ROUTES.USER.PROFILE} exact component={UserProfile} />
      <PrivateRoute path={config.ROUTES.USER.INFO} exact component={UserInfo} />      

      <PrivateRoute path={config.ROUTES.TRANSACTIONS} exact component={Transactions} />
      <PrivateRoute path={config.ROUTES.CONSUMPTIONS} exact component={Consumptions} />
      <PrivateRoute path={config.ROUTES.ASSISTANCES} exact component={Assistances} />

      <PrivateRoute path={config.ROUTES.COLLABORATORS} exact component={Users} />
      <PrivateRoute path={config.ROUTES.COLLABORATORS_NEW} exact component={User} />
      <PrivateRoute path={config.ROUTES.COLLABORATORS_EDIT} exact component={User} />
      
      <PrivateRoute path={config.ROUTES.BENEFITS} exact component={Benefits} />
      <PrivateRoute path={config.ROUTES.BENEFITS_CONSUMPTIONS} exact component={BenefitsConsumptions} />
      <PrivateRoute path={config.ROUTES.BENEFIT_CONSUMPTION} exact component={BenefitConsumption} />
      {/* <PrivateRoute path={config.ROUTES.BENEFITS_COLABORADORES} exact component={Benefits} /> */}
      <PrivateRoute path={config.ROUTES.BENEFITS_NEW} exact component={Benefit} />
      <PrivateRoute path={config.ROUTES.BENEFITS_EDIT} exact component={Benefit} />

      <PrivateRoute path={config.ROUTES.MY_SETTINGS} exact component={Settings} />
      {/* <PrivateRoute path={config.ROUTES.MY_SETTINGS_NEW} exact component={Settings} />
      <PrivateRoute path={config.ROUTES.MY_SETTINGS_EDIT} exact component={Settings} /> */}
      
      {/* <PrivateRoute path={config.ROUTES.INITIATIVES_ATTENDANCE} exact component={InitiativeAttendance} />
      <PrivateRoute path={config.ROUTES.INITIATIVES_ATTENDANCE_CONFIRMATION} exact component={InitiativeAttendanceConfirmation} /> */}

      <PrivateRoute path={config.ROUTES.BENEFITS_CONSUME} exact component={BenefitConsume} />
      <PrivateRoute path={config.ROUTES.BENEFITS_CONSUME_CONFIRMATION} exact component={BenefitConsumeConfirmation} />

      <PrivateRoute path={config.ROUTES.BENEFITS_SCANQR} exact component={ScanQR} />
      <PrivateRoute path={config.ROUTES.SCANQR} exact component={ScanQR} />

      {/* <PrivateRoute path={config.ROUTES.BENEFITS_LIST} exact component={BenefitList} />
      <PrivateRoute path={config.ROUTES.BENEFIT_SINGLE} exact component={SingleBenefit} /> */}

      <PrivateRoute path={config.ROUTES.EXCEL} exact component={UploadExcelUsers} />

      <PrivateRoute path={config.ROUTES.CHALLENGES} exact component={ChallengesList} />
      <PrivateRoute path={config.ROUTES.CHALLENGES_NEW} exact component={ChallengesEdit} />
      <PrivateRoute path={config.ROUTES.CHALLENGES_EDIT} exact component={ChallengesEdit} />

      <PrivateRoute path={config.ROUTES.REFERENTS} exact component={Referents} />
      <PrivateRoute path={config.ROUTES.REFERENTS_NEW} exact component={Referent} />
      <PrivateRoute path={config.ROUTES.REFERENTS_EDIT} exact component={Referent} />
      <PrivateRoute path={config.ROUTES.REFERENTS_EXCEL} exact component={UploadExcelUsers} />

      <PrivateRoute path={config.ROUTES.ADMINISTRATORS} exact component={Colaboradores} />
      <PrivateRoute path={config.ROUTES.ADMINISTRATORS_NEW} exact component={Colaborador} />
      <PrivateRoute path={config.ROUTES.ADMINISTRATORS_EDIT} exact component={Colaborador} />

      <PrivateRoute path={config.ROUTES.RANKING} exact component={UserRanking} />
      <PrivateRoute path={config.ROUTES.CREDITS_COMPANY} exact component={Credits} />
      <PrivateRoute path={config.ROUTES.CREDITS_COLLABORATORS} exact component={Credits} />
      <PrivateRoute path={config.ROUTES.CREDITS_ONE_COLLABORATOR} exact component={OneUserHistory} />
      <PrivateRoute path={config.ROUTES.MODERADOR_ACCIONES} component={Moderador} />

      {/* HOME */}
      <PrivateRoute path={config.ROUTES.HOME} exact component={Dashboard} />

      <Route>
        <Error title={t('404')} headline={t('Page not found!')} button={t('Go back')}/>
      </Route>        
    </Switch>
  );
}

